import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin, Switch, Progress } from 'antd'
import QueueAnim from 'rc-queue-anim'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import { API_ERRO_TYPE_CANCEL } from './../../config/general'

import {
  companiesService,
  departmentsService,
  employeesService,
  journeysService,
  recalculateSchedulesService,
} from '../../redux/services'
import { DatePickerWithMask } from '../../components'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSending: false,
      progress: 0,
      nextOrder: 1,
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: [],
      employees: [],
      employeesIsLoading: false,
      journeys: [],
      journeysIsLoading: false,
      showDatesFields  : false,
      somenteInativos: false
    }

    this._axiosCancelCompaniesToken = null
    this._axiosCancelDepartmentsToken = null
    this._axiosCancelEmployeesToken = null
    this._axiosCancelJourneysToken = null
  }

  fetchCompanies = () => {
    if (this._axiosCancelCompaniesToken) {
      this._axiosCancelCompaniesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelCompaniesToken = axios.CancelToken.source()

    this.setState({
      companiesIsLoading: true,
    })

    companiesService
      .getAutocomplete({
        orderBy: 'nome:asc',
        ativo: 1,
        cancelToken: this._axiosCancelCompaniesToken.token,
      })
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companies: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          companiesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchDepartments = (value) => {
    if (this._axiosCancelDepartmentsToken) {
      this._axiosCancelDepartmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelDepartmentsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        departmentsIsLoading: false,
        departments: [],
      })

      return false
    }

    this.setState({
      departmentsIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelDepartmentsToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
    }

    departmentsService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          departmentsIsLoading: false,
          departments: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          departmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchEmployees = (value) => {
    if (this._axiosCancelEmployeesToken) {
      this._axiosCancelEmployeesToken.cancel(
        'Only one request allowed at a time.'
      );
    }

    this._axiosCancelEmployeesToken = axios.CancelToken.source();

    if (!value.trim().length) {
      this.setState({
        employeesIsLoading: false,
        employees: [],
      });
      return false;
    }

    this.setState({
      employeesIsLoading: true,
    });

    let params = {
      search: value,
      ativo: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelEmployeesToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
      departamentos_ids: this.form
        .getFieldValue('departamentos')
        .includes('todos')
        ? []
        : this.form.getFieldValue('departamentos'),
    };

    employeesService
      .getAutocomplete({
         ...params,
        })
      .then((response) => {
        this.setState({
          employeesIsLoading: false,
          employees: response.data.data,
        });
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

        this.setState({
          employeesIsLoading: false,
        });

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        });
      });
  }

  fetchJourneys = (value) => {
    if (this._axiosCancelJourneysToken) {
      this._axiosCancelJourneysToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelJourneysToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        journeysIsLoading: false,
        journeys: [],
      })

      return false
    }

    this.setState({
      journeysIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'descricao:asc',
      cancelToken: this._axiosCancelJourneysToken.token,
    }

    journeysService
      .getAll({
        ...params,
      })
      .then((response) => {
        this.setState({
          journeysIsLoading: false,
          journeys: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          journeysIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  estimateTime = (numEmployees, dateRange) => {
    if (!numEmployees || !dateRange) return;
    const timeForOneEmployeeIn31Days = 5.5;
  
    const rangeDays = moment(dateRange[1]).diff(moment(dateRange[0]), 'days') + 1;
  
    const adjustedBaseTimePerEmployeePerDay = timeForOneEmployeeIn31Days / 31;
  
    const estimatedTime = numEmployees * adjustedBaseTimePerEmployeePerDay * rangeDays;
  
    return Math.ceil(estimatedTime)
  };

  startProgress = (estimatedTime) => {
    let elapsedTime = 0;

    this.setState({ progress: 0, isSending: true });

    this.progressInterval = setInterval(() => {
      elapsedTime += 1;
      const progress = Math.min((elapsedTime / estimatedTime) * 100, 99.99);
      this.setState({ progress });

      if (progress >= 99.99) {
        clearInterval(this.progressInterval);
      }
    }, 1000);
  };  

  onCompanyChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'empresas',
        value.filter((item) => item !== 'todos')
      )
    }

    this.setState({
      employees: [],
      employeesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
    });

    this.form.setFieldValue('departamentos', ['todos'])
    this.form.setFieldValue('funcionarios', ['todos']);

    this.fetchEmployees('a');
  }

  onCompanySelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('empresas', ['todos'])
    }
  }

  onDepartmentChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'departamentos',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onDepartmentSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('departamentos', ['todos'])
    }
  }

  onEmployeeChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'funcionarios',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onEmployeeSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('funcionarios', ['todos'])
    }
  }

  componentDidMount() {
    if (!this.state.companies.length) {
      this.fetchCompanies()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.companies !== this.state.companies && this.state.companies.length > 0) {
      this.fetchEmployees('a');
    }
  }  

  resetFields = () => {
    this.setState({
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
      journeys: [],
      journeysIsLoading: false,
      showDatesFields: false,
    })
  }

  onFinish = (values) => {
    const { employees } = this.state;
    this.setState({ isSending: true });
    values.reordenar = false;

    const data = { ...values };

    if (!(data.empresas instanceof Array)) {
      data.empresas = [data.empresas]
    }

    if (!(data.departamentos instanceof Array)) {
      data.departamentos = [data.departamentos]
    }

    if (!(data.funcionarios instanceof Array)) {
      data.funcionarios = [data.funcionarios]
    }

    if (data.empresas.includes('todos')) {
      data.empresas = null
    }

    if (data.departamentos.includes('todos')) {
      data.departamentos = null
    }

    if (data.funcionarios.includes('todos')) {
      data.funcionarios = null
    }

    if (data.data_inicio) {
      data.data_inicial = moment(data.data_inicio).format('YYYY-MM-DD')
    }

    if (data.data_fim) {
      data.data_final = moment(data.data_fim).format('YYYY-MM-DD')
    }

    let numEmployees = values?.funcionarios?.length;
    if (!data.funcionarios) {
      numEmployees = employees?.length;
    }

    const dateRange = [moment(data.data_inicio).format('YYYY-MM-DD'), moment(data.data_fim).format('YYYY-MM-DD')];
    const estimatedTime = this.estimateTime(numEmployees, dateRange);
    this.startProgress(estimatedTime);

    delete data.data_inicio
    delete data.data_fim

    recalculateSchedulesService
      .create(data)
      .then((response) => {
        clearInterval(this.progressInterval);
        this.setState({ progress: 100, isSending: false });

        // Reset fields
        this.resetFields();

        Modal.success({
          content: 'Recalculo realizado com sucesso!',
          okText: 'Ok',
          onOk: () => window.location.reload(false),
        });
      })
      .catch((data) => {
        clearInterval(this.progressInterval);
        this.setState({ isSending: false, progress: 0 });

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        });
      });
  };

  onValuesChange = (changedValues) => {
    if (changedValues.hasOwnProperty('somente_inativos')) {
      this.setState({ somenteInativos: changedValues.somente_inativos });
      if (changedValues.somente_inativos) {
        this.form.setFieldsValue({ funcionarios: ['todos'] });
      }
    }
  };

  render() {
    const {
      isLoading,
      isSending,
      nextOrder,
      companies,
      companiesIsLoading,
      departments,
      departmentsIsLoading,
      employees,
      employeesIsLoading,
      journeys,
      journeysIsLoading,
      showDatesFields,
      somenteInativos,
      progress
    } = this.state

    const formattedProgress = progress?.toFixed(2);

    return (
      <Fragment>
      <QueueAnim className="site-content-inner alternative-wrapper">
        <div className="page-content fixed-header" key="1">
          <h1 className="page-title">Recalcular Horários</h1>
          <Form
            ref={(el) => (this.form = el)}
            layout="vertical"
            scrollToFirstError
            onFinish={this.onFinish}
            onValuesChange={this.onValuesChange}
            initialValues={{
              order: nextOrder,
              required: true,
              manter_jornada_original: false,
              reordenar: false,
              somente_inativos: false,
              empresas: ['todos'],
              departamentos: ['todos'],
              funcionarios: ['todos'],
            }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="empresas"
                  label="Empresa"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    ref={(el) => (this.empresas = el)}
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (typeof option.children === 'string'
                        ? option.children
                        : option.children.props.children
                      )
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Selecione a(s) empresa(s)"
                    notFoundContent={
                      companiesIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onChange={this.onCompanyChange}
                    onSelect={this.onCompanySelect}
                    showSearch
                  >
                    <Select.Option value="todos">Todas</Select.Option>
                    {companies.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="departamentos"
                  label="Departamento"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (typeof option.children === 'string'
                        ? option.children
                        : option.children.props.children
                      )
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Selecione o(s) departamento(s)"
                    notFoundContent={
                      departmentsIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onSearch={this.fetchDepartments}
                    onChange={this.onDepartmentChange}
                    onSelect={this.onDepartmentSelect}
                    showSearch
                  >
                    <Select.Option value="todos">Todos</Select.Option>
                    {departments.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="funcionarios"
                  label="Funcionário"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Select
                    mode="multiple"
                    filterOption={false}
                    allowClear
                    placeholder="Selecione o(s) funcionário(s)"
                    notFoundContent={
                      employeesIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onSearch={this.fetchEmployees}
                    onChange={this.onEmployeeChange}
                    onSelect={this.onEmployeeSelect}
                    showSearch
                    disabled={somenteInativos}
                  >
                    <Select.Option value="todos">Todos</Select.Option>
                    {employees.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={4}>
                <Form.Item
                  name="somente_inativos"
                  label="Recalcular Somente Inativos"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="jornada_id"
                  label="Horário"
                >
                  <Select
                    filterOption={false}
                    allowClear
                    placeholder="Selecione o horário (jornada)"
                    notFoundContent={
                      journeysIsLoading ? (
                        <Spin
                          indicator={
                            <i className="fad fa-spinner-third fa-spin" />
                          }
                        />
                      ) : null
                    }
                    onSearch={this.fetchJourneys}
                    showSearch
                  >
                    {journeys.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.descricao}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {!showDatesFields && (
                <Fragment>
                  <Col xs={24} md={6} lg={6}>
                    <DatePickerWithMask
                      label="Data Início"
                      name="data_inicio"
                      onKeyUp={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 9) {
                          this.form.data_fim.focus()
                        }
                      }}
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório.',
                        },
                        {
                          validator: async (_, data_inicio) => {
                            var data_fim = this.form.getFieldValue('data_fim')
                            if (data_fim !== null) {
                              if (data_inicio > data_fim) {
                                return Promise.reject(
                                  'A data inicial deve ser menor ou igual a data final.'
                                )
                              }
                            }
                          },
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={24} md={6} lg={6}>
                    <DatePickerWithMask
                      label="Data Final"
                      name="data_fim"
                      onKeyUp={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 9) {
                          this.form.data_inicio.focus()
                        }
                      }}
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório.',
                        },
                        {
                          validator: async (_, data_fim) => {
                            var data_inicio = this.form.getFieldValue('data_inicio')
                            if (data_inicio !== null) {
                              if (data_fim < data_inicio) {
                                return Promise.reject(
                                  'A data final deve ser maior ou igual a data inicial.'
                                )
                              }
                            }
                          },
                        },
                      ]}
                    />
                  </Col>
                </Fragment>
              )}
              <Col xs={24} md={6} lg={4}>
                <Form.Item
                  name="manter_jornada_original"
                  label="Aplicar Horário do Cadastro"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>

              {/* <Col xs={24} md={6} lg={4}>
                <Form.Item
                  name="reordenar"
                  label="Aplicar Reordenação"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col> */}

              <Col xs={24} md={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  className="btn-save"
                  icon={<i className="far fa-check" />}
                  loading={isSending}
                  disabled={isSending || isLoading}
                >
                  {isSending ? 'Carregando' : 'Recalcular'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </QueueAnim>
      <Modal
        title="Estamos recalculando os horários. Isso pode levar alguns minutos. Por favor, aguarde..."
        visible={this.state.isSending}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <div style={{ textAlign: 'center' }}>
          <Spin
            indicator={
              <i className="fad fa-spinner-third fa-spin" />
            }
          />
          <Progress
            percent={formattedProgress}
          />
        </div>
      </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
  }
}

export default connect(mapStateToProps)(Index)
